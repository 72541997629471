import React, {createContext, useState} from "react";

import Steps from "../components/Steps";
import IngredientsForm from "./IngredientsForm";
import CompanyForm from "./CompanyForm";
import "../css/form.css"
import {Unit} from "../components/UnitSelect";
import axios from "axios";
import {NUTRICAL_REQUEST_API} from "../config/config";
import Loader from "../components/Loader";
import ProductForm from "./ProductForm";
import useDefaults from "../hooks/useDefaults";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import SuccessfulSubmit from "../components/successfulSubmit";
import VitaminsForm from "./VitaminsForm";
import {SubscriptionType} from "../components/SubscriptionCard";


export const NutricalRequestContext = createContext(null)

const Form = () => {

    const [stepDefault, nutricalRequestDefault] = useDefaults()
    const [nutricalRequest, setNutricalRequest] = useState(nutricalRequestDefault)
    const [step, setStep] = useState(stepDefault)
    const [displaySubmitSuccess, setDisplaySubmitSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const convertNutricalRequest = (data) => {
        return {
            ...data,
            ingredients: data.ingredients.filter(e => !(!e.value && !e.name && !e.description))
        }
    }

    const submitForm = () => {
        setIsLoading(true)
        axios({
            method: 'POST',
            url: `${NUTRICAL_REQUEST_API}`,
            data: convertNutricalRequest(nutricalRequest)
        }).then(() => {
            setDisplaySubmitSuccess(true)
        }).catch(e => {
            console.log(e)
            alert("Došlo je do greške. Molimo vas pokušajte ponovo.")
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const backToHome = () => {
        window.location.href = "https://nutrical.rs"
    }

    const startNewRequest = () => {
        setNutricalRequest({
            ...nutricalRequestDefault,
            ingredients: [{
                unit: Unit.G
            }, {
                unit: Unit.G
            }, {
                unit: Unit.G
            }],
            customer_name: nutricalRequest.customer_name,
            customer_address: nutricalRequest.customer_address,
            customer_vat_number: nutricalRequest.customer_vat_number,
            customer_registration_number: nutricalRequest.customer_registration_number,
            customer_phone: nutricalRequest.customer_phone,
            contact_name: nutricalRequest.contact_name,
            customer_email: nutricalRequest.customer_email,
            nutrients: [],
            customer_city: nutricalRequest.customer_city,
        })

        setStep(1)
        setDisplaySubmitSuccess(false)
    }

    const scrollTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }


    let index = 1;

    return (
        <NutricalRequestContext.Provider value={{nutricalRequest, setNutricalRequest}}>
            <Loader isLoading={isLoading}>
                <div>
                    <Navigation/>
                    <div className="">

                        {!displaySubmitSuccess &&
                            <div className="pt-[0px] xl:pt-[100px]">
                                <div className="Form">
                                    <div className="hidden xl:block title">
                                        Online zahtev za izradu nutritivne deklaracije / deklaracije hrane
                                    </div>
                                    <div className="status xl:col-start-2 xl:col-end-3">
                                        <Steps step={step} setStep={setStep}
                                               showVitamins={nutricalRequest?.subscription_package === SubscriptionType.PREMIUM}/>
                                    </div>
                                    <div className="content col-start-2 xl:col-start-3 col-end-12">
                                        <ProductForm hide={step !== 1}
                                                     stepIndex={1}
                                                     onNext={() => {
                                                         setStep(2)
                                                         scrollTop()
                                                     }}/>

                                        <IngredientsForm hide={step !== 2}
                                                         stepIndex={2}
                                                         onNext={() => {
                                                             setStep(nutricalRequest?.subscription_package === SubscriptionType.PREMIUM ? 3 : 4)
                                                             scrollTop()
                                                         }}
                                                         onBack={() => {
                                                             setStep(1)
                                                             scrollTop();
                                                         }}/>
                                        <VitaminsForm hide={step !== 3}
                                                      stepIndex={3}
                                                     onBack={() => {
                                                         setStep(2)
                                                         scrollTop()
                                                     }}
                                                     onNext={() => {
                                                         setStep(4)
                                                         scrollTop()
                                                     }}/>
                                        <CompanyForm hide={step !== 4}
                                                     stepIndex={nutricalRequest?.subscription_package === SubscriptionType.PREMIUM ? 4 : 3}
                                                     onBack={() => {
                                                         setStep(nutricalRequest?.subscription_package === SubscriptionType.PREMIUM ? 3 : 2)
                                                         scrollTop()
                                                     }}
                                                     onNext={() => {
                                                         submitForm()
                                                         scrollTop()
                                                     }}/>
                                    </div>
                                    <div className="col-4"></div>
                                </div>
                            </div>
                        }
                        {displaySubmitSuccess &&
                            <SuccessfulSubmit displaySubmitModal={displaySubmitSuccess}
                                              backToHome={backToHome}
                                              startNewRequest={startNewRequest}/>
                        }
                    </div>
                    <Footer/>
                </div>
            </Loader>
        </NutricalRequestContext.Provider>
    );
}

export default Form