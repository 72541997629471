import React, {useState} from "react"
import {InfoIcon} from "./Icons";
import "../css/tooltip.css"
import Button from "./Button";
import classNames from "classnames";

const Tooltip = (props) => {

    const [isVisible, setVisible] = useState(false)
    const {isOptional} = props

    const toggleDescription = () => {
        setVisible(!isVisible);
    }

    const tooltipClassNames = classNames(props.className, "Tooltip")

    return (
        <div className={tooltipClassNames}>
            { isVisible &&
                <div className="description">
                    <div>
                        {props.children}
                    </div>
                    <div className="mt-2">
                        <Button size="large" color="blue" onClick={() => setVisible(false)}>U redu</Button>
                    </div>
                </div>
            }
            <div className="title">
                <div>
                    <span>{props.title}</span>
                    { isOptional && <span className="optional-input-indicator">(Opciono)</span> }
                </div>
                { props.children &&
                    <div className="icon-wrapper">
                        {isVisible && <div className="arrow"></div>}
                        <InfoIcon  onClick={toggleDescription}/>
                    </div>
                }
            </div>
        </div>
    )
}

export default Tooltip