import React from "react"
import {ReactComponent as FacebookIcon} from "../components/icons/FacebookLogo.svg";
import {ReactComponent as InstagramIcon} from "../components/icons/InstagramLogo.svg";
import {ReactComponent as LinkedinIcon} from "../components/icons/LinkedinLogo.svg";


const Footer = () => {

    return (
        <div id="contact"
             className="w-full bg-footer hidden xl:grid grid-cols-[2fr,280px,repeat(9,1fr),2fr]
             pt-[60px] pb-[40px] text-white">

            <div className="col-start-2">
                <div>
                    <img src="/Logo_Dark.svg"/>
                </div>
                <div className="pl-4 mt-4">
                    Sva prava zadrzana.
                </div>
                <div className="pl-4">
                    Copyright 2023
                </div>
            </div>

            <div className="col-start-3 col-span-9  flex flex-column justify-end items-baseline">
                <div>
                    <div className="font-semibold text-md">Kontakt</div>
                    <div className="mt-6">
                        <div>Nutrikator D.O.O. </div>
                        <div>Petra Martinovica 25, Beograd</div>
                        <div>+381 60 398 30 11</div>
                        <div>info@nutrikator.com</div>
                    </div>
                    <div className="flex mt-4">
                        <a href="https://www.instagram.com/nutrikator" >
                            <InstagramIcon className="fill-white hover:fill-[#FC634B]"/>
                        </a>
                        <a href="https://www.facebook.com/nutrikator" className="ml-2">
                            <FacebookIcon className="fill-white hover:fill-[#FC634B]"/>
                        </a>

                        <a href="https://www.linkedin.com/company/nutrikator" className="ml-2">
                            <LinkedinIcon className="fill-white hover:fill-[#FC634B]"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer