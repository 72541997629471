import React, {useState} from "react";
import classNames from "classnames";

import "../css/unitSelect.css"
import {CaretDownIcon} from "./Icons";

export const Unit = {
    G: "G",
    ML: "ML",
    PCS: "PCS",
}

const UnitSelect = (props) => {

    const {value, onChange, label, id, className} = props
    const [isEmpty, setIsEmpty] = useState(!value)

    const handleOnChangeEvent = (e) => {
        setIsEmpty(!e.target.value)
        onChange(e)
    }

    const classes = classNames(className, {
        "UnitSelect": true,
        "empty": isEmpty
    })

    const isSmallScreen = window.innerWidth < 768;

    const g_Label = isSmallScreen ? 'g' : 'Gram (g)'
    const ml_Label = isSmallScreen ? 'ml' : 'Mililitar (ml)'
    const kom_Label = isSmallScreen ? 'kom' : 'Komad'

    return (
        <div className={classes}>
            {label && <label htmlFor={id} className="label">{label}</label>}
            {!props.children &&
                <select name="" id={id} value={value} onChange={handleOnChangeEvent}>
                    <option value={Unit.G}>{g_Label}</option>
                    <option value={Unit.ML}>{ml_Label}</option>
                    <option value={Unit.PCS}>{kom_Label}</option>
                </select>
            }
            {
                props.children &&
                <select name="" id={id} value={value} onChange={handleOnChangeEvent}>
                    {props.children}
                </select>
            }
            <div className="px-4"><CaretDownIcon/></div>
        </div>
    )
}

export default UnitSelect