export const NUMBER_REGEX = "^[0-9]*$"
export const DECIMAL_NUMBER_REGEX = "^(?![.])(?!0[0-9])([0-9]*[.])?[0-9]*$"

export const API_BASE = "https://api.nutrikator.com/v1"

export const DIGITAL_MENU_API = API_BASE + "/digital-menu/restaurants"

export const NUTRICAL_REQUEST_API = API_BASE + "/nutrical-requests"
export const MEDIA_API = API_BASE + "/media"

export const terms_policy_url = "https://nutrikator.com/uslovi-poslovanja"
export const business_policy_url = "https://nutrikator.com/politika-privatnosti"
export const cookies_policy_url = "https://nutrikator.com/politika-upotrebe-kolacica"

